import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import { SessionAuthenticator, withSessionContext, SessionContext } from 'clouden-components/context/session'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import { Support } from 'clouden-components/views/support'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'

interface SupportProps extends WithTranslation {
  location: any
  notificationContext: NotificationContext
  sessionContext: SessionContext
}

class AuthenticatedSupportPage extends React.Component<SupportProps> {
  render() {
    return (
      <div className="container mt-5">
        <Support location={this.props.location}/>
      </div>
    )
  }
}

class SupportView extends React.Component<SupportProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"Support"} description="Support" />
        <SessionAuthenticator>
          <AuthenticatedSupportPage {...this.props} />
        </SessionAuthenticator>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(withSessionContext(SupportView)))
